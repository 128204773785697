<template>
  <div id="userForm">

    <!-- Action Buttons -->
    <b-button v-if="action === 'add' && $checkPermission('users', 'insert')"
              @click="addUser"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Ավելացնել
    </b-button>
    <b-button v-if="action === 'edit' && $checkPermission('users', 'update')"
              @click="editUser"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Խմբագրել
    </b-button>
    <b-button
        @click="$emit('updated')"
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Վերադառնալ
    </b-button>

    <validation-observer ref="userValidation">

      <b-form-checkbox
          v-model="form.twoFactor"
          name="check-button"
          class="custom-control-success ml-2"
          switch
          inline
      >
        Երկփուլային նույնականացում
      </b-form-checkbox>

      <b-form-checkbox
          v-model="form.showCalendar"
          name="check-button"
          class="custom-control-success ml-2"
          switch
          inline
      >
        Ցուցադրել ամսաթիվը
      </b-form-checkbox>

      <b-form-checkbox
          v-model="form.showPrint"
          name="check-button"
          class="custom-control-success ml-2"
          switch
          inline
      >
        Թույլատրել տպել
      </b-form-checkbox>

      <b-form-checkbox
          v-if="selectedUserType === 'ADMIN'"
          v-model="form.support"
          name="check-button"
          class="custom-control-success ml-2"
          switch
          inline
      >
        Ստանալ ծանուցումներ կայաններից
      </b-form-checkbox>

      <b-form autocomplete="off" class="form">
        <b-row>

          <b-col
              cols="12"
              md="3"
          >
            <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
            >
              <b-form-group
                  label="Խումբ"
                  label-for="user-type"
              >
                <v-select
                    @input="changeType"
                    v-model="form.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="all_types"
                    :reduce="prefix => prefix.id"
                    :clearable="false"
                    input-id="user-status"
                    autocomplete="new-fuckable-select"
                    :class="{'is-invalid': !!errors.length}"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Username -->
          <b-col
              cols="12"
              md="3"
          >

            <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
            >
              <b-form-group
                  label="Անուն"
                  label-for="name"
              >
                <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
              cols="12"
              md="3"
          >
            <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
            >

              <b-form-group
                  label="Էլ․ փոստ"
                  label-for="email"
              >
                <b-form-input
                    id="full-email"
                    v-model="form.email"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                />
              </b-form-group>

            </validation-provider>
          </b-col>

          <!-- Field: Role -->
          <b-col
              cols="12"
              md="3"
          >

            <validation-provider v-if="action === 'add'"
                                 #default="{ errors }"
                                 name="Password"
                                 rules="required|min:6"
            >

              <b-form-group
                  label="Գաղտնաբառ"
                  label-for="password"
              >
                <b-form-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                />
              </b-form-group>

            </validation-provider>
            <b-form-group v-else
                          label="Գաղտնաբառ"
                          label-for="password_edit"
            >
              <b-form-input
                  id="password_edit"
                  v-model="form.password"
                  type="password"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="3"
          >

            <validation-provider v-if="action === 'add'"
                                 #default="{ errors }"
                                 name="Password"
                                 rules="required|min:6"
            >

              <b-form-group
                  label="Հեռախոսահամար"
                  label-for="phone"
              >
                <b-form-input
                    id="phone"
                    v-model="form.phone"
                    :state="errors.length > 0 ? false:null"
                />
              </b-form-group>

            </validation-provider>
            <b-form-group v-else
                          label="Հեռախոսահամար"
                          label-for="phone_edit"
            >
              <b-form-input
                  id="phone_edit"
                  v-model="form.phone"
              />
            </b-form-group>
          </b-col>


          <b-col
              cols="12"
              md="12"
          >

            <validation-provider
                #default="{ errors }"
                name="Pages"
                rules="required"
            >

              <b-form-group
                  label="Էջեր"
                  label-for="user-pages"
              >
                <v-select
                    @input="changePage"
                    v-model="form.pages"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="pages"
                    :reduce="prefix => prefix.id"
                    multiple
                    :class="(!!errors.length) ? 'select-size-lg is-invalid' : 'select-size-lg'"
                />
              </b-form-group>

            </validation-provider>
          </b-col>

          <b-col v-if="selectedUserType === 'TECHNICAL_SERVICE_OWNER' || selectedUserType === 'VIEWER'"
              cols="12"
              md="12"
          >

            <validation-provider
                #default="{ errors }"
                name="Companies"
            >

              <b-form-group
                  label="Կազմակերպություններ"
                  label-for="user-pages"
              >
                <v-select
                    v-model="form.companies"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="companies"
                    :reduce="prefix => prefix.id"
                    multiple
                    :class="(!!errors.length) ? 'select-size-lg is-invalid' : 'select-size-lg'"
                />
              </b-form-group>

            </validation-provider>
          </b-col>
          <b-col v-if="selectedUserType === 'TECHNICAL_SERVICE_OPERATOR' || selectedUserType === 'TECHNICAL_SERVICE_MECHANIC' "
              cols="12"
              md="12"
          >

            <validation-provider
                #default="{ errors }"
                name="Companies"
                rules="required"
            >

              <b-form-group
                  label="Կազմակերպություններ"
                  label-for="user-pages"
              >
                <v-select
                    v-model="form.company"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="companies"
                    :reduce="prefix => prefix.id"
                    :class="(!!errors.length) ? 'select-size-lg is-invalid' : 'select-size-lg'"
                />
              </b-form-group>

            </validation-provider>
          </b-col>


        </b-row>
      </b-form>

    </validation-observer>

    <!-- PERMISSION TABLE -->
    <b-card
        no-body
        class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
              icon="LockIcon"
              size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
          responsive
          :fields="fields"
          striped
          class="mb-0"
          :items="form.permissions"
      >
        <template #cell(name)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox @input="changePermission(data)" :checked="data.value"/>
        </template>
      </b-table>
    </b-card>


  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BMedia,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: ['form', 'user_types', 'all_types', 'action'],
  data() {
    return {
      USERS_STORE_MODULE_NAME: 'usersModule',
      fields: [
        {
          key: 'name',
          label: 'Էջ',
        },
        {
          key: 'view',
          label: 'Դիտել',
        },
        {
          key: 'insert',
          label: 'Ավելացնել',
        },
        {
          key: 'update',
          label: 'Խմբագրել',
        },
        {
          key: 'delete',
          label: 'Հեռացնել',
        }
      ]
    }
  },
  computed: {
    pages: {
      get() {
        return this.$store.state[this.USERS_STORE_MODULE_NAME].pages
      },
      set(data) {
        this.$store.commit(this.USERS_STORE_MODULE_NAME + '/SET_PAGES', data)
      }
    },
    companies () {
      return this.$store.state[this.USERS_STORE_MODULE_NAME].companies
    },
    permissionsData: {
      get() {
        return this.$store.state[this.USERS_STORE_MODULE_NAME].permissionsData
      },
      set(data) {
        this.$store.commit(this.USERS_STORE_MODULE_NAME + '/SET_PERMISSIONS_DATA', data)
      }
    },
    selectedUserType () {
      let name = ''
      const type = this.form.type
      if (type) {
        const index = this.user_types.findIndex((i) => i.id === type)
        if (index >= 0) {
          name = this.user_types[index].label
        }
      }

      return name
    }
  },
  setup(props) {

    const roleOptions = [
      {
        label: 'Admin',
        value: 'admin'
      },
      {
        label: 'Author',
        value: 'author'
      },
      {
        label: 'Editor',
        value: 'editor'
      },
      {
        label: 'Maintainer',
        value: 'maintainer'
      },
      {
        label: 'Subscriber',
        value: 'subscriber'
      },
    ]

    const statusOptions = [
      {
        label: 'Pending',
        value: 'pending'
      },
      {
        label: 'Active',
        value: 'active'
      },
      {
        label: 'Inactive',
        value: 'inactive'
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      avatarText,
      roleOptions,
      statusOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {
    changeType(type) {
      this.form.pages = []
      this.form.permissions = []
      this.form.companies = []
      const index = this.user_types.findIndex((i) => i.id === type)
      if (index >= 0) {
        const pages = this.user_types[index].pages
        const result = []
        pages.map((item) => {
          let temp = {
            id: item.id,
            title: item.title
          }
          result.push(temp)
        })
        this.pages = result
      }
    },
    changePage(pages) {
      this.form.permissions = []
      for (let i = 0; i < pages.length; i++) {
        let index = this.pages.findIndex((p) => p.id === pages[i])
        if (index >= 0) {
          let temp = {
            name: this.pages[index].title,
            page: this.pages[index].id,
            view: true,
            insert: true,
            update: true,
            delete: true,
          }
          this.form.permissions.push(temp)
        }
      }
    },
    changePermission(data) {
      const field = data.field
      const item = data.item
      item[field.key] = !item[field.key]
    },
    addUser() {
      this.$refs.userValidation.validate()
          .then(success => {
            if (success) {
              this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/addUser', this.form)
                  .then(() => {
                    this.$emit('updated')
                    this.$swal({
                      icon: 'success',
                      title: '',
                      text: 'Օգտատերն ավելացվել է',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })

    },
    editUser() {
      if (this.action === 'edit' && this.form.password === '') {
        delete this.form.password
      }
      this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/editUser', this.form)
          .then(() => {
            this.$emit('updated')
            this.$swal({
              icon: 'success',
              title: '',
              text: 'Փոփոխությունները պահպանվեցին',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
          })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#users {
  #userForm {
    padding: 2rem;

    .form {
      margin-top: 1rem;
    }

    .table-responsive {
      max-height: calc(var(--vh, 1vh) * 100 - 40rem);
      min-height: calc(var(--vh, 1vh) * 100 - 40rem);
    }
  }
}

</style>
